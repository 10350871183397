<template>
	<div>
		<TableList :columns="columns" :form_data_seo="form_data_seo" :get_table_list="this.get_table_list"
			:slot_table_list="['operation', 'is_group','name','uid','principal','is_sync']" :is_page="false" rowKey="id"
			ref="list">
			<template slot="left_btn">
				<a-button class="float_left" v-auth="'change'" @click="add()" type="primary">添加部门</a-button>
			</template>
			<template slot="right_btn">
				<a :href="'/manage/crm.export/framework' | export_form" v-if="this.$store.state.page_auth.export"
					target="_blank">
					<a-button class="a_btn">导出</a-button>
				</a>
			</template>
			<template slot="name" slot-scope="data">
				{{data.text}}{{data.record.status == -1 ? '(取消)': ''}}
			</template>
			<template slot="is_group" slot-scope="data">
				<span v-if="data.text == 1">有</span>
				<span v-else-if="data.text == 0">无</span>
			</template>
			<template slot="is_sync" slot-scope="data">
				<span v-if="data.text==-1">否</span>
				<span v-else-if="data.text==1">是</span>
			</template>
			<template slot="uid" slot-scope="data">
				<a @click="add_user(data.record)" :style="data.record.head_user?'':'color:#999'">
				
				{{data.record.head_user?data.record.head_user:'请选择'}}
				
				</a>

			</template>
			<template slot="principal" slot-scope="data">
				<a @click="add_user2(data.record)" :style="data.record.principal_user?'':'color:#999'">{{data.record.principal_user?data.record.principal_user:'请选择'}}</a>
			</template>

			<template slot="operation" slot-scope="data">
				<a v-auth="'change'" @click="edit(data.record, 'add')" v-if='!data.record.drp_id || data.record.show_transfer == 1'>添加下级部门</a>
				<a-divider v-auth="'change'" type="vertical" v-if='!data.record.drp_id || data.record.show_transfer == 1' />
				<a v-auth="'change'" @click="transfer(data.record)" v-if='!data.record.drp_id || data.record.show_transfer == 1'>转移</a>
				<a-divider v-auth="'change'" type="vertical" v-if='!data.record.drp_id || data.record.show_transfer == 1' />
				<a v-auth="'change'" v-if='!data.record.drp_id || data.record.show_edit == 1 || data.record.show_transfer == 1' @click="edit(data.record)">编辑</a>
				<a-divider v-auth="'change'" type="vertical" v-if='!data.record.drp_id || data.record.show_edit == 1 || data.record.show_transfer == 1' />
				<a v-if="data.record.status == -1" v-auth="'delete'" @click="valid(data.record)">有效</a>
				<a-popconfirm title="您确定要删除吗?" v-if="data.record.status == 1" v-auth="'delete'" @confirm="del(data.record)">
					<a v-if='!data.record.drp_id || data.record.show_transfer == 1'>删除</a>
				</a-popconfirm>
				<a-divider v-auth="'change'" v-if='!data.record.drp_id || data.record.show_transfer == 1' type="vertical" />
				<a v-auth="'change'" @click="area(data.record)">{{ data.record.region === 0 ? "设为区域" : "取消区域" }}</a>
			</template>
		</TableList>
		
		<!-- submit -->
		
		<EditPop ref='EditPop' @handleSubmit="submit" :form_data="form_data" :visible.sync="visible">
			<div slot="uid">
				<span class="manage_main" v-if="select_user.username">
					<span class="manage_user">
						{{ select_user.username }}
						<img src="../../assets/img/icon_delete.png" @click="del_user"/>
					</span>
				</span>
				<a @click="add_user()"><span style="padding-left: 5px">选择负责人</span></a>
				<a-input type="hidden" name="uid" />
			</div>
			<div slot="principal">
				<span class="manage_main" v-if="select_user2.username">
					<span class="manage_user">
						{{ select_user2.username }}
						<img src="../../assets/img/icon_delete.png" @click="del_user2"/>
					</span>
				</span>
				<a @click="add_user2()">
					<span style="padding-left: 5px">选择分管领导</span>
				</a>
				<a-input type="hidden" name="uid" />
			</div>
		</EditPop>

		<SelectUser @submit="submituser" :visible.sync="uservisible"></SelectUser>
		<SelectUser @submit="submituser2" :visible.sync="uservisible2"></SelectUser>
		<EditPop @handleSubmit="transfer_submit" :form_data="transfer_form_data" :visible.sync="transfer_visible">
		</EditPop>
	</div>
</template>
<script>
	import SelectUser from "@/components/SelectUser";
	import EditPop from "@/components/EditPop";
	import Form from "@/components/Form";
	import TableList from "@/components/TableList";
	import {
		getFramework,
		changeFramework,
		delFramework,
		transferFramework,
		setRegion,
		validFramework
	} from '@/api/user'
	import {
		getOrganizationInfo
	} from '@/api/enterprise'
	const columns = [{
			title: "部门名称",
			dataIndex: "name",
			scopedSlots: {
				customRender: "name"
			}
		},
		{
			title: "部门代码",
			dataIndex: "code",
		},
		{
			title: "负责人",
			dataIndex: "uid",
			scopedSlots: {
				customRender: "uid"
			}
		}, {
			title: "分管领导",
			dataIndex: "principal",
			scopedSlots: {
				customRender: "principal"
			}
		},
		{
			title: "所属区域",
			dataIndex: "region_name",
		},
		{
			title: "机构ID",
			dataIndex: "drp_id",
		},
		{
			title: "部门ID",
			dataIndex: "id",
		},
		{
			title: "部门类型",
			dataIndex: "type",
		},

		{
			title: "部门编制",
			dataIndex: "headcount",
		},


		{
			title: "员工数",
			dataIndex: "user_num",
		},
		{
			title: "部门群",
			dataIndex: "is_group",
			scopedSlots: {
				customRender: "is_group"
			}
		},
		{
			title: "是否同步人事系统",
			dataIndex: "is_sync",
			scopedSlots: {
				customRender: "is_sync"
			}
		},
		
		{
			title: "操作",
			scopedSlots: {
				customRender: "operation"
			}
		}
	];

	export default {
		name: "Index",
		components: {
			EditPop,
			Form,
			TableList,
			SelectUser
		},
		data() {
			return {
				uservisible: false,
				uservisible2: false,
				select_user: {},
				select_user2: {},
				OrganizationArr: [],
				get_table_list: getFramework,
				columns,
				visible: false,
				form_data_seo: {
					...this.$config.form_data_seo,
					list: [{
						type: "text",
						name: "name",
						title: "部门",
						options: {}
					}],
				},
				transfer_visible: false,
				transfer_form_data: {
					title: "转移",
					list: [{
							type: 'text',
							name: 'id',
							hidden: true,
							options: {},
						},
						{
							type: "tree-select",
							name: "pid",
							title: "部门",
							options: {
								rules: [{
									required: true,
									message: "请选择部门"
								}]
							},
							treeData: [],
							multiple: false
						}
					]
				},

				edit_add: false,
				form_data: {
					title: "添加",
					list: [{
							type: 'text',
							name: 'id',
							hidden: true,
							options: {},
						},
						{
							type: 'text',
							name: 'pid',
							hidden: true,
							options: {},
						},
						{
							type: "text",
							name: "name",
							title: "部门名称",
							options: {}
						},
						{
							type: "select",
							name: "type_id",
							title: "部门类型",
							options: {},
							list: []
						},
						{
							type: "text",
							title: "部门代码",
							name: "code",
							options: {}
						},
						{
							type: "text",
							name: "headcount",
							title: "部门编制",
							options: {}
						},

						{
							type: "slot",
							name: "uid",
							title: "负责人",
							// required: true,
							options: {
								initialValue: []
							}
						},
						{
							type: "slot",
							name: "principal",
							title: "分管领导",
							// required: true,
							options: {
								initialValue: []
							}
						},


						{
							type: "text",
							type: "text",
							name: "drp_id",
							title: "机构ID",
							options: {}
						},
						{
							type: "radio",
							name: "is_sync",
							title: "同步人事系统",
							options: {
								rules: [{
									required: false,
									message: "请选择是否同步人事系统"
								}],
								initialValue: 1
							},
							list: [{
									key: 1,
									value: "是"
								},
								{
									key: -1,
									value: "否"
								}
							]
						},
					]
				}
			};
		},
		created() {
			this.get_option()
		},
		methods: {
			del_user(){
				this.select_user = {}
				this.form_data.list[6].options.initialValue = ''
			},
			del_user2(){
				this.select_user2 = {}
				this.form_data.list[7].options.initialValue = ''
			},
			submituser({
				data
			}) {
				this.uservisible = false;
				this.select_user = data;
				this.form_data.list[6].options.initialValue = this.select_user.id
				if (this.edit_add) {
				this.change_user()
				}
			},
			submituser2({
				data
			}) {
				this.uservisible2 = false;
				this.select_user2 = data;
				this.form_data.list[7].options.initialValue = this.select_user2.id
				if (this.edit_add) {
					this.change_user()
				}
			},
			change_user(){
				let obj = {}
				this.form_data.list.forEach(item=>{
					obj[item.name] = item.options.initialValue
				})
				changeFramework({
					data: {
						...obj,
						pid:this.form_data.list[1].options.initialValue,
						principal: this.form_data.list[7].options.initialValue
					},
					info: true,
				}).then(res => {
					this.select_user = {}
					this.select_user2 = {}
					this.edit_add = false
					this.$refs.list.get_list();
				})
			},
			add_user(data) {
				this.edit_add = false
				if (data) {
					this.edit_add = true
					this.select_user = {
						username: data.head_user,
						id: data.uid
					}
					this.form_data.list.forEach(item => {
						item.options.initialValue = data[item.name]
					})
					this.form_data.list[6].options.initialValue = data.uid
				}
				
				this.uservisible = true;
			},
			add_user2(data) {
				this.edit_add = false
				if (data) {
					this.edit_add = true
					this.select_user2 = {
						username: data.principal_user,
						id: data.principal
					}
					this.form_data.list.forEach(item => {
						item.options.initialValue = data[item.name]
					})
					this.form_data.list[7].options.initialValue =data.principal_user
				}
				this.uservisible2 = true;
			},
			get_option() {
				// getOrganizationInfo().then(res => {
				// 	res.data.list.forEach(item => {
				// 		this.OrganizationArr.push({
				// 			value: item.name,
				// 			key: item.info_id
				// 		})

				// 	})
				// 	this.form_data.list.forEach(item => {
				// 		if (item.name == 'type_id') {
				// 			item.list = this.OrganizationArr
				// 		} 
				// 	})
				// })
			},
			add() {
				this.form_data.title = "添加"
				this.form_data.list.forEach(item => {
					item.options.initialValue = '';
				})
				this.select_user={}
				this.select_user2={}
				this.visible = true;
			},
			del(data) {
				delFramework({
					data: {
						id: data.id
					},
					info: true,
				}).then(res => {
					this.$refs.list.get_list();
				})
			},
			edit(data, type = 'edit') {
				this.select_user={}
				this.select_user2={}
				if (type == 'add') {
					this.form_data.title = "添加"
					this.form_data.list.forEach(item => {
						if (item.name == 'pid') {
							item.options.initialValue = data['id'];
						} else {
							item.options.initialValue = '';
						}
					})
				} else {
					this.form_data.title = "编辑"
					this.form_data.list.forEach(item => {
						if(item.name=='is_sync'){
							item.options.initialValue = data[item.name];
							item.disabled = data.sync_edit==1?false:true
							// sync_edit
						}else{
							item.options.initialValue = data[item.name];
						}
						
					})
					this.select_user = {
						username: data.head_user,
						id: data.uid
					}
					this.select_user2 = {
						username: data.principal_user,
						id: data.principal
					}
				}
				this.visible = true;
			},
			transfer(data) {
				this.transfer_form_data.list.forEach(item => {
					if (item.name != 'pid') {
						item.options.initialValue = data[item.name];
					}
				})
				this.transfer_visible = true;

				this.$method.get_department(true).then(res => {
					this.transfer_form_data.list.forEach(item => {
						if (item.name == "pid") {
							item.treeData = res;
						}
					});
				});
			},
			valid(data) {
				validFramework({
					data: {
						id: data.id,
					},
					info: true,
				}).then(res => {
					this.$refs.list.get_list();
				})
			},
			transfer_submit({
				values
			}) {
				transferFramework({
					data: {
						...values,
					}
				}).then(res => {
					this.transfer_visible = false;
					this.$refs.list.get_list();
				})
			},
			area(record) {
				setRegion({
					data: {
						department_id: record.id,
						status: record.region === 0 ? 1 : -1
					},
					info: true,
				}).then(res => {
					this.$refs.list.get_list();

				})
			},
			submit(e) {
				if (!e.values.id) {
					e.values.id = '';
				}
				changeFramework({
					data: {
						...e.values,
						uid: this.form_data.list[6].options.initialValue,
						principal: this.form_data.list[7].options.initialValue
					},
					info: true,
				}).then(res => {
					this.visible = false;
					this.select_user = {}
					this.select_user2 = {}
					this.edit_add = false
					this.$refs.list.get_list();
				})
			}

		}
	};
</script>

<style lang="less" scoped>
.manage_main{
    margin-right: 10px;
    display:inline-block;

    .manage_user{
        border:1px #eeeeee solid;
        border-radius:5px;
        padding:5px;

        img{
            width:16px;
            margin-top:-3px;
            cursor: pointer;
        }
    }
}
</style>